import * as actionTypes from './actionTypes';
import * as api from './api';




export const getSourceStatsByDateRange = (start, end) => async dispatch => {
    dispatch({ type: actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.REQUEST });
    return api.getSourceStatsByDateRange(start, end)
        .then(response => {
            dispatch({
                type: actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.ERROR });
        });
};




export const getInventoryThisMonth = (pageSize) => async dispatch => {
    dispatch({ type: actionTypes.GET_THIS_MONTH.REQUEST });
    return api.getInventoryThisMonthDaily(pageSize)
        .then(response => {
            dispatch({
                type: actionTypes.GET_THIS_MONTH.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_THIS_MONTH.ERROR });
        });
};

export const getInventoryThisYear = (pageSize) => async dispatch => {
    dispatch({ type: actionTypes.GET_THIS_YEAR.REQUEST });
    return api.getInventoryThisYearMonthly(pageSize)
        .then(response => {
            dispatch({
                type: actionTypes.GET_THIS_YEAR.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_THIS_YEAR.ERROR });
        });
};


export const getInventorySoldStats = (pageSize, from, to) => async dispatch => {
    dispatch({ type: actionTypes.GET_SOLD_STATS_BY_DATE_RANGE.REQUEST });
    return api.getInventorySoldStats(pageSize, from, to)
        .then(response => {
            dispatch({
                type: actionTypes.GET_SOLD_STATS_BY_DATE_RANGE.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_SOLD_STATS_BY_DATE_RANGE.ERROR });
        });
};

export const getInventoryForSourceOrSubsource = (pageSize, source, subsource, from, to) => async dispatch => {
    dispatch({ type: actionTypes.GET_BY_SOURCE_SUBSOURCE.REQUEST });
    return api.getInventoryForSourceOrSubsource(pageSize, source, subsource, from, to)
        .then(response => {
            dispatch({
                type: actionTypes.GET_BY_SOURCE_SUBSOURCE.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_BY_SOURCE_SUBSOURCE.ERROR });
        });
};

export const getAllSourcesAndSubsources = (pageSize) => async dispatch => {
    dispatch({ type: actionTypes.GET_ALL_SOURCES_SUBSOURCES.REQUEST });
    return api.getAllSourcesAndSubsources(pageSize)
        .then(response => {
            dispatch({
                type: actionTypes.GET_ALL_SOURCES_SUBSOURCES.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_ALL_SOURCES_SUBSOURCES.ERROR });
        });
};

export const sourceSelected = (data) => async dispatch => {
    dispatch({ type: actionTypes.SOURCE_CHANGED.SUCCESS, payload: data });
    getInventoryForSourceOrSubsource(data)
};

export const subsourceSelected = (data) => async dispatch => {
    dispatch({ type: actionTypes.SUBSOURCE_CHANGED.SUCCESS, payload: data });
};


export const sourceForOrdersSelected = (data) => async dispatch => {
    dispatch({ type: actionTypes.SOURCE_ORDERS_CHANGED.SUCCESS, payload: data });
};

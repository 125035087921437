import _ from "lodash";
import {NAME} from "./constants";

export const getInventoryThisMonth = (state) => {
    const inventoryThisMonth = _.get(state[NAME], "inventoryThisMonth");
    return inventoryThisMonth;
};

export const getInventoryThisYear = (state) => {
    const inventoryThisYear = _.get(state[NAME], "inventoryThisYear");
    return inventoryThisYear;
};

export const getInventorySoldStats = (state) => {
    const inventorySoldStats = _.get(state[NAME], "inventorySoldStats");
    return inventorySoldStats;
};

export const getInventoryForSourceOrSubsource = (state) => {
    const inventoryForSourceOrSubsource = _.get(state[NAME], "inventoryForSourceOrSubsource");
    return inventoryForSourceOrSubsource;
};

export const getSoldBySourceYearly = (state, measurement) => {
    const yearlySourceStats = _.get(state[NAME], "yearlySourceStats.reportBySourceAndYear", []);

    const result = [];

    yearlySourceStats.forEach(item => {
        const index = result.findIndex(el => el.id === item.source);
        if (index >= 0) {
            if (measurement.value === "ordersCount") {
                result[index].data.push(
                    {
                        x: item.year,
                        y: Number((item.received).toFixed(2))
                    });
            } else {
                result[index].data.push(
                    {
                        x: item.year,
                        y: Number((item.totalCharge).toFixed(2))

                    });
            }
        } else {
            if (measurement.value === "ordersCount") {
                result.push({
                    id: item.source,
                    data: [
                        {
                            x: item.year,
                            y: Number((item.received).toFixed(2))
                        }
                    ]
                });
            } else {
                result.push({
                    id: item.source,
                    data: [
                        {
                            x: item.year,
                            y: Number((item.totalCharge).toFixed(2))
                        }
                    ]
                });
            }
        }
    });
    return result;
};


export const getAllSources = (state) => {
    const allSourcesAndSubsources = _.get(state[NAME], "allSourcesAndSubsources");

    const uniqSources = _.uniqBy(allSourcesAndSubsources, 'source');
    const result = uniqSources.map(i => ({
        value: i.source,
        label: i.source
    }));
    return result;
};

export const getSubSources = (state) => {
    const allSourcesAndSubsources = _.get(state[NAME], "allSourcesAndSubsources");
    const source = _.get(state[NAME], "selectedSource");
    if(!source){
        return [{value: '%', label: 'All'}];
    }
    const filtered = allSourcesAndSubsources.filter(x => x.source === source.value);
    const result = filtered.map(i =>
        ({
        value: i.subsource,
        label: i.subsource
    }));
    result.unshift({value: '%', label: 'All'});
    return result;
};



export const getRevenueSoldBySource = state => {
    const dailyStatsBySource = _.get(state[NAME], 'dailySourceStats');
    const selected = dailyStatsBySource.map(item => {
            return {
                source: item.source,
                processed: Number((Math.round(item.processedCharge * 100) / 100).toFixed(2)),
                opened: Number((Math.round((item.totalCharge - item.processedCharge) * 100) / 100).toFixed(2)),
                total: Number((Math.round(item.totalCharge * 100) / 100).toFixed(2))
            };
    });
    const grouped = [];
    selected.forEach(item => {
        const index = grouped.findIndex(el => el.source === item.source);
        if (index >= 0) {
            grouped[index].processed = Number((Math.round((grouped[index].processed + item.processed) * 100) / 100).toFixed(2));
            grouped[index].opened = Number((Math.round((grouped[index].opened + item.opened) * 100) / 100).toFixed(2));
            grouped[index].total = Number((Math.round((grouped[index].total + item.total) * 100) / 100).toFixed(2));
        } else {
            grouped.push(item);
        }
    });
    return grouped;
};

export const getCountSoldBySource = state => {
    const dailyStatsBySource = _.get(state[NAME], 'dailySourceStats');
    const selected = dailyStatsBySource.map(item => {
        return {
                source: item.source,
                processed: item.processed,
                opened: item.received - item.processed,
                total: item.received
            };
    });
    const grouped = [];
    selected.forEach(item => {
        const index = grouped.findIndex(el => el.source === item.source);
        if (index >= 0) {
            grouped[index].processed = Number((Math.round((grouped[index].processed + item.processed) * 100) / 100).toFixed(2));
            grouped[index].opened = Number((Math.round((grouped[index].opened + item.opened) * 100) / 100).toFixed(2));
            grouped[index].total = Number((Math.round((grouped[index].total + item.total) * 100) / 100).toFixed(2));
        } else {
            grouped.push(item);
        }
    });
    return grouped;
};

export const getRevenueSoldBySubSource = (state) => {
    const dailyStatsBySource = _.get(state[NAME], 'dailySourceStats');
    const selected = dailyStatsBySource.filter(item => item.source === state[NAME].selectedSource).map(item => {
            return {
                subsource: item.subsource,
                processed: Number((Math.round(item.processedCharge * 100) / 100).toFixed(2)),
                opened: Number((Math.round((item.totalCharge - item.processedCharge) * 100) / 100).toFixed(2)),
                total: Number((Math.round(item.totalCharge * 100) / 100).toFixed(2))
            };
    });
    const grouped = [];
    selected.forEach(item => {
        const index = grouped.findIndex(el => el.subsource === item.subsource);
        if (index >= 0) {
            grouped[index].processed = Number((Math.round((grouped[index].processed + item.processed) * 100) / 100).toFixed(2));
            grouped[index].opened = Number((Math.round((grouped[index].opened + item.opened) * 100) / 100).toFixed(2));
            grouped[index].total = Number((Math.round((grouped[index].total + item.total) * 100) / 100).toFixed(2));
        } else {
            grouped.push(item);
        }
    });
    return grouped;
};


export const getCountSoldBySubSource = (state) => {
    const dailyStatsBySource = _.get(state[NAME], 'dailySourceStats');
    const selected = dailyStatsBySource.filter(item => item.source === state[NAME].selectedSource).map(item => {
            return {
                subsource: item.subsource,
                processed: item.processed,
                opened: item.received - item.processed,
                total: item.received
            };
    });
    const grouped = [];
    selected.forEach(item => {
        const index = grouped.findIndex(el => el.subsource === item.subsource);
        if (index >= 0) {
            grouped[index].processed = Number((Math.round((grouped[index].processed + item.processed) * 100) / 100).toFixed(2));
            grouped[index].opened = Number((Math.round((grouped[index].opened + item.opened) * 100) / 100).toFixed(2));
            grouped[index].total = Number((Math.round((grouped[index].total + item.total) * 100) / 100).toFixed(2));
        } else {
            grouped.push(item);
        }
    });
    return grouped;
};




export const getDailySourceStatsLoading = (state) => _.get(state[NAME], 'dailySourceStatsLoading');
export const getAllSourcesAndSubsourcesLoading = (state) => _.get(state[NAME], "allSourcesAndSubsourcesLoading");
export const getInventoryThisMonthLoading = (state) => _.get(state[NAME], "inventoryThisMonthLoading");
export const getInventoryThisYearLoading = (state) => _.get(state[NAME], "inventoryThisYearLoading");
export const getInventorySoldStatsLoading = (state) => _.get(state[NAME], "inventorySoldStatsLoading");
export const getInventoryForSourceOrSubsourceLoading = (state) => _.get(state[NAME], "inventoryForSourceOrSubsourceLoading");


export const getSelectedSource = state => state[NAME].selectedSource;
export const getSelectedSubSource = state => {
    const selectedSubsource = state[NAME].selectedSubsource;
if(!selectedSubsource) return {value: '%', label: 'All'};
return selectedSubsource};

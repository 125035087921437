import React from 'react';
import _ from 'lodash';
import ChartCheckboxItem from '../ChartCheckboxItem/ChartCheckboxItem';
import './сhartWrapper.css';

class ChartWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            items: props.data
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.data, this.props.data)) {
            this.setState({ items: this.props.data }, this.callback);
        }
    }

    isSelected = item => {
        return !!_.find(this.state.items, i => i[this.props.textDisplayKey] === item);
    };

    callback = () => {
        this.props.onSelectItems(this.state.items);
    };

    onClickCheckbox = item => {
        if (this.isSelected(item[this.props.textDisplayKey])) {
            this.setState(prevState => ({ items: _.filter(prevState.items, it => it[this.props.textDisplayKey] !== item[this.props.textDisplayKey]) }), this.callback);
        } else {
            this.setState(prevState => ({ items: [...prevState.items, item] }), this.callback);
        }
    };

    renderCheckboxItem = item => {
        return (
            <ChartCheckboxItem
                key={item[this.props.textDisplayKey]}
                item={item}
                isSelected={this.isSelected(item[this.props.textDisplayKey])}
                onClick={this.onClickCheckbox}
                textDisplayKey={this.props.textDisplayKey}
            />
        );
    };

    render() {
        const { children, title, data, description } = this.props;
        return (
            <div className="chartWrapper">
                <p>{title} {description ? <span>{description}</span> : null}</p>
                <div className="chartWrapperItems">
                    {_.map(data, this.renderCheckboxItem)}
                </div>
                <div className="chartWrapperContent">
                    {children}
                </div>
            </div>
        );
    }
}

export default ChartWrapper;
import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist";
import {NAME, ALL_DASHBOARD_MEASUREMENTS} from "./constants";
import * as actionTypes from "./actionTypes";

const initialState = {
    dailySourceStats: [],
    dailySourceStatsLoading: false,
    inventoryThisMonth: {
        singleInventoryStatsModel: [],
        compositeInventoryStatsModel: [],
        singleInventoryCostStatsModel: [],
        compositeInventoryCostStatsModel: []
    },
    inventoryThisMonthLoading: false,
    inventoryThisYear: {
        singleInventoryStatsModel: [],
        compositeInventoryStatsModel: [],
        singleInventoryCostStatsModel: [],
        compositeInventoryCostStatsModel: []
    },
    inventoryThisYearLoading: false,
    inventorySoldStats: {
        singleInventoryStatsModel: [],
        compositeInventoryStatsModel: [],
        singleInventoryCostStatsModel: [],
        compositeInventoryCostStatsModel: []
    },
    inventorySoldStatsLoading: false,
    inventoryForSourceOrSubsource: {
        singleInventoryStatsModel: [],
        compositeInventoryStatsModel: [],
        singleInventoryCostStatsModel: [],
        compositeInventoryCostStatsModel: []
    },
    inventoryForSourceOrSubsourceLoading: false,

    allSourcesAndSubsources: [],
    allSourcesAndSubsourcesLoading: [],


    selectedSource: null,
    selectedOrdersSource: null,
    selectedSubsource: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.REQUEST:
            return {
                ...state,
                dailySourceStatsLoading: true
            };
        case actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.SUCCESS:
            return {
                ...state,
                dailySourceStats: action.payload,
                dailySourceStatsLoading: false,
                selectedSource: action.payload[0].source
            };
        case actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.ERROR:
            return {
                ...state,
                dailySourceStatsLoading: false,
                selectedSource: null
            };
        case actionTypes.GET_THIS_MONTH.REQUEST:
            return {
                ...state,
                inventoryThisMonthLoading: true
            };
        case actionTypes.GET_THIS_MONTH.SUCCESS:
            return {
                ...state,
                inventoryThisMonth: action.payload,
                inventoryThisMonthLoading: false
            };
        case actionTypes.GET_THIS_MONTH.ERROR:
            return {
                ...state,
                inventoryThisMonthLoading: false
            };

        case actionTypes.GET_THIS_YEAR.REQUEST:
            return {
                ...state,
                inventoryThisYearLoading: true
            };
        case actionTypes.GET_THIS_YEAR.SUCCESS:
            return {
                ...state,
                inventoryThisYear: action.payload,
                inventoryThisYearLoading: false
            };
        case actionTypes.GET_THIS_YEAR.ERROR:
            return {
                ...state,
                inventoryThisYearLoading: false
            };

        case actionTypes.GET_SOLD_STATS_BY_DATE_RANGE.REQUEST:
            return {
                ...state,
                inventorySoldStatsLoading: true
            };
        case actionTypes.GET_SOLD_STATS_BY_DATE_RANGE.SUCCESS:
            return {
                ...state,
                inventorySoldStats: action.payload,
                inventorySoldStatsLoading: false
            };
        case actionTypes.GET_SOLD_STATS_BY_DATE_RANGE.ERROR:
            return {
                ...state,
                inventorySoldStatsLoading: false
            };

        case actionTypes.GET_BY_SOURCE_SUBSOURCE.REQUEST:
            return {
                ...state,
                inventoryForSourceOrSubsourceLoading: true
            };
        case actionTypes.GET_BY_SOURCE_SUBSOURCE.SUCCESS:
            return {
                ...state,
                inventoryForSourceOrSubsource: action.payload,
                inventoryForSourceOrSubsourceLoading: false
            };
        case actionTypes.GET_BY_SOURCE_SUBSOURCE.ERROR:
            return {
                ...state,
                inventoryForSourceOrSubsourceLoading: false
            };

        case actionTypes.GET_ALL_SOURCES_SUBSOURCES.REQUEST:
            return {
                ...state,
                allSourcesAndSubsourcesLoading: true
            };
        case actionTypes.GET_ALL_SOURCES_SUBSOURCES.SUCCESS:
            return {
                ...state,
                allSourcesAndSubsources: action.payload,
                allSourcesAndSubsourcesLoading: false
            };
        case actionTypes.GET_ALL_SOURCES_SUBSOURCES.ERROR:
            return {
                ...state,
                allSourcesAndSubsourcesLoading: false
            };

        case actionTypes.SOURCE_CHANGED.SUCCESS:
            return {
                ...state,
                selectedSource: action.payload,
                selectedSubsource: null
            };

        case actionTypes.SUBSOURCE_CHANGED.SUCCESS:
            return {
                ...state,
                selectedSubsource: action.payload
            };


        case actionTypes.SOURCE_ORDERS_CHANGED.SUCCESS:
            return {
                ...state,
                selectedOrdersSource: action.payload
            };


        default:
            return state;
    }
};

const persistConfig = {
    key: NAME,
    storage,
    whitelist: []
};

export default persistReducer(persistConfig, reducer);
import axios from 'axios';

export const endpoints = {
    sourceStatsByDateRange: (start, end) => `linnworksDashboard/getBySourceAndSubsource/${start.format('MM-DD-YYYY')}/${end.format('MM-DD-YYYY')}`,
    allSourcesAndSubsources: () => `linnworksDashboard/getAllSourcesAndSubsources`,
    inventoryThisMonthDaily: (pageSize) => `linnworksDashboard/getInventoryThisMonthDaily/${pageSize}`,
    inventoryThisYearMonthly: (pageSize) => `linnworksDashboard/getInventoryThisYearMonthly/${pageSize}`,
    inventorySoldStats: (pageSize, start, end) => `linnworksDashboard/getInventorySoldStats/${pageSize}/${start.format('MM-DD-YYYY')}/${end.format('MM-DD-YYYY')}`,
    inventoryForSourceOrSubsource: (pageSize, source, subsource, start, end) => encodeURI(`linnworksDashboard/getInventoryForSourceOrSubsource/${pageSize}/${start.format('MM-DD-YYYY')}/${end.format('MM-DD-YYYY')}/${source}/${subsource}`),
};


export const getSourceStatsByDateRange = (start, end) => {
    return axios.get(endpoints.sourceStatsByDateRange(start, end));
};


export const getInventoryThisMonthDaily = pageSize => {
    return axios.get(endpoints.inventoryThisMonthDaily(pageSize));
};

export const getInventoryThisYearMonthly = pageSize => {
    return axios.get(endpoints.inventoryThisYearMonthly(pageSize));
};


export const getInventorySoldStats = (pageSize, start, end) => {
    return axios.get(endpoints.inventorySoldStats(pageSize, start, end));
};

export const getInventoryForSourceOrSubsource = (pageSize, source, subsource, start, end) => {
    return axios.get(endpoints.inventoryForSourceOrSubsource(pageSize, source, subsource, start, end));
};

export const getAllSourcesAndSubsources = () => {
    return axios.get(endpoints.allSourcesAndSubsources());
};
import React from 'react';
import './chartCheckboxItem.css';

class ChartCheckboxItem extends React.PureComponent {
    onClick = () => {
        this.props.onClick(this.props.item);
    };

    render() {
        const { item, isSelected } = this.props;
        return (
            <div className={`chartCheckbox ${isSelected ? 'chartCheckbox--selected' : ''}`}
                onClick={this.onClick}>
                {item[this.props.textDisplayKey] || 'NONAME'}
            </div>
        );
    }
}

export default ChartCheckboxItem;
import React, {Component} from 'react';
import {ResponsiveLine} from '@nivo/line';
import Loader from '../../core/Components/Loader';
import ChartWrapper from '../../core/Components/ChartWrapper/ChartWrapper';
import _ from "lodash";


class LineChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.data, this.props.data)) {
            this.setState({ data: this.props.data });
        }
    }

    onSelectItems = data => {
        this.setState({ data });
    };

    render() {
        const {data, loading, title, textDisplayKey, legendLeftText, customColors, toolTipPrefix, toolTipPostfix} = this.props;
        let content = null;

        if(loading){
            content = <Loader loading={loading}/>;
        }

        if (data && (!data.length || !this.state.data.length) && !loading) {
            content = <p className={'noDataMessage'}>Not enough data to show you this great chart :(</p>;
        }
        if(data && data.length > 0 && !loading && this.state.data.length){
            content = (<ResponsiveLine
                stacked={false}
                data={this.state.data}
                margin={{top: 50, right: 110, bottom: 50, left: 60}}
                xScale={{type: 'point'}}
                yScale={{type: 'linear', stacked: false, min: 'auto', max: 'auto'}}
                curve="catmullRom"
                axisTop={null}
                axisRight={null}
                enablePointLabel={true}
                enableSlices="x"
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Year',
                    legendOffset: 36,
                    legendPosition: 'middle'
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: legendLeftText,
                    legendOffset: -50,
                    legendPosition: 'middle'
                }}
                colors={customColors ? customColors : ['#3f51b5', '#2196f3', '#03a9f4', '#14c0d6', '#9ebcda', '#9e9ac8', '#02818a', '#b2abd2']}
                pointSize={10}
                pointColor={{theme: 'background'}}
                pointBorderWidth={2}
                pointBorderColor={{from: 'serieColor'}}
                pointLabel="y"
                pointLabelYOffset={-12}
                enableArea={true}
                areaBlendMode="multiply"
                areaOpacity={0.25}
                useMesh={false}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                sliceTooltip={({slice}) => {
                    debugger;
                    return (
                        <div
                            style={{
                                background: 'white',
                                padding: '9px 12px',
                                border: '1px solid #ccc',
                            }}
                        >
                            <div><strong>{slice.points[0].data.x}</strong></div>
                            {slice.points.map(point => (
                                <div
                                    key={point.id}
                                    style={{
                                        color: point.serieColor,
                                        padding: '3px 0',
                                    }}
                                >
                                    {point.serieId}: <strong>{toolTipPrefix}{point.data.yFormatted}{toolTipPostfix}</strong>
                                </div>
                            ))}
                        </div>
                    );
                }}
            />);
        }

        return (
            <ChartWrapper title={title} data={data} onSelectItems={this.onSelectItems} textDisplayKey={textDisplayKey}>
                {content}
            </ChartWrapper>
        );
    }
}


export default LineChart;

import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import DateRangePicker from "react-daterange-picker";
import BarChart from "./Barchart";
import LineChart from "./Linechart";
import Select from "react-select";
import {
    getInventoryThisMonth,
    getInventoryThisYear,
    getInventorySoldStats,
    getInventoryForSourceOrSubsource,
    sourceSelected,
    subsourceSelected,
    sourceForOrdersSelected,
    getAllSourcesAndSubsources,
    getSourceStatsByDateRange
} from "../actions";
import {Col, Grid, Row} from "react-flexbox-grid";
import * as selectors from "../selectors";
import "react-daterange-picker/dist/css/react-calendar.css";
import "./dashboard.css";
import "react-tabs/style/react-tabs.css";

class Dashboard extends Component {
    onDateRangeChange = dates => {
        this.setState({dates}, () => {
            this.props.getInventoryForSourceOrSubsource(this.state.pageSize.value, "all", "all", dates.start, dates.end);
            this.props.getSourceStatsByDateRange(dates.start, dates.end);
        });
    };

    onSourceChange = (selected) => {
        const {pageSize} = this.state;
        const {start, end} = this.state.dates;
        this.props.sourceSelected(selected);
        this.props.getInventoryForSourceOrSubsource(pageSize.value, selected.value, "%", start, end);
    };

    onSubsourceChange = (selected) => {
        const {pageSize} = this.state;
        const {start, end} = this.state.dates;
        const {selectedSource} = this.props;
        this.props.subsourceSelected(selected);
        this.props.getInventoryForSourceOrSubsource(pageSize.value, selectedSource.value, selected.value, start, end);
    };

    onSizeChanged = (selected) => {
        this.setState({pageSize: selected}, () => {
            const {start, end} = this.state.dates;
            const {selectedSource, selectedSubsource} = this.props;
            this.props.getAllSourcesAndSubsources(selected.value);
            this.props.getInventoryThisMonth(selected.value);
            this.props.getInventoryThisYear(selected.value);
            this.props.getInventorySoldStats(selected.value, start, end);
            this.props.getInventoryForSourceOrSubsource(selected.value, selectedSource ? selectedSource.value : "%", selectedSubsource ? selectedSubsource.value : "%", start, end);
        });
    };

    onSourceClick = (node) => {
        this.props.sourceForOrdersSelected(node.data.source);
    };

    constructor(props) {
        super(props);
        this.state = {
            dates: moment().range(moment().startOf("day").add(-14, "days"), moment().startOf("day")),
            pageSize: {value: 50, label: "50 items"}
        };
    }

    UNSAFE_componentWillMount() {
        const {start, end} = this.state.dates;
        const {selectedSource, selectedSubsource} = this.props;
        this.props.getAllSourcesAndSubsources(20);
        this.props.getInventoryThisMonth(20);
        this.props.getInventoryThisYear(20);
        this.props.getInventorySoldStats(20, start, end);
        this.props.getInventoryForSourceOrSubsource(20, selectedSource ? selectedSource.value : "%", selectedSubsource ? selectedSubsource.value : "%", start, end);
        this.props.getSourceStatsByDateRange(start, end);
    }

    render() {
        const {
            soldRevenueBySource,
            soldRevenueBySubSource,
            soldCountBySource,
            soldCountBySubSource,
            dailySourceStatsLoading,
            inventoryThisMonth,
            inventoryThisYear,
            inventorySoldStats,
            inventoryForSourceOrSubsource,
            inventoryThisMonthLoading,
            inventoryThisYearLoading,
            inventorySoldStatsLoading,
            inventoryForSourceOrSubsourceLoading,
            selectedSource,
            selectedSubsource,
            allSources,
            allSubsources,
            allSourcesLoading
        } = this.props;
        const {pageSize} = this.state;
        return (

            <Grid fluid className="dashboardContainer">

                <h2>General Settings</h2>
                <Row className="dashboardHeader">
                    <Col lg={3}>
                        <Select options={
                            [
                                {
                                    value: 10,
                                    label: "10 items"
                                },
                                {
                                    value: 20,
                                    label: "20 items"
                                },
                                {
                                    value: 50,
                                    label: "50 items"
                                },
                                {
                                    value: 100,
                                    label: "100 items"
                                }
                            ]
                        }
                                onChange={this.onSizeChanged}
                                placeholder={"Select Page Size"}
                                value={pageSize}
                        />
                    </Col>
                    <Col className="dashboardHeaderDate">
                        <DateRangePicker
                            numberOfCalendars={3}
                            onSelect={this.onDateRangeChange}
                            value={this.state.dates}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <BarChart indexBy={'source'}
                                  description={'* Press on any Source to see all SubSources!'}
                                  data={soldRevenueBySource}
                                  loading={dailySourceStatsLoading}
                                  onClick={this.onSourceClick}
                                  title="Sold by source"
                                  textDisplayKey="source"
                        />
                    </Col>
                    <Col lg={6}>
                        <BarChart indexBy={'subsource'}
                                  data={soldRevenueBySubSource}
                                  loading={dailySourceStatsLoading}
                                  title={selectedSource ? `Sold by sub source: ${selectedSource}`: 'Select Source to view this chart!'}
                                  textDisplayKey="subsource"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <BarChart indexBy={'source'}
                                  description={'* Press on any Source to see all SubSources!'}
                                  data={soldCountBySource}
                                  loading={dailySourceStatsLoading}
                                  onClick={this.onSourceClick}
                                  title="Sold by source"
                                  textDisplayKey="source"
                        />
                    </Col>
                    <Col lg={6}>
                        <BarChart indexBy={'subsource'}
                                  data={soldCountBySubSource}
                                  loading={dailySourceStatsLoading}
                                  title={selectedSource ? `Sold by sub source: ${selectedSource}`: 'Select Source to view this chart!'}
                                  textDisplayKey="subsource"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <BarChart indexBy={"channelSKU"}
                                  data={inventoryForSourceOrSubsource.singleInventoryStatsModel}
                                  loading={inventoryForSourceOrSubsourceLoading}
                                  title={`Top ${pageSize.value} Single SKU’s sold (processed only by date selected)`}
                                  textDisplayKey="channelSKU"
                                  groupMode={"grouped"}
                                  colorScheme={"paired"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <BarChart indexBy={"channelSKU"}
                                  data={inventoryForSourceOrSubsource.compositeInventoryStatsModel}
                                  loading={inventoryForSourceOrSubsourceLoading}
                                  title={`Top ${pageSize.value} SKU’s sold including composites (processed only by date selected)`}
                                  textDisplayKey="channelSKU"
                                  groupMode={"grouped"}
                                  colorScheme={"category10"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <BarChart indexBy={"channelSKU"}
                                  data={inventoryForSourceOrSubsource.singleInventoryCostStatsModel}
                                  loading={inventoryForSourceOrSubsourceLoading}
                                  title={`Top ${pageSize.value} SKU’s revenue including composites (processed only by date selected)`}
                                  textDisplayKey="channelSKU"
                                  groupMode={"grouped"}
                        />
                    </Col>
                </Row>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const inventoryThisMonth = selectors.getInventoryThisMonth(state);
    const inventoryThisYear = selectors.getInventoryThisYear(state);
    const inventorySoldStats = selectors.getInventorySoldStats(state);
    const inventoryForSourceOrSubsource = selectors.getInventoryForSourceOrSubsource(state);
    const inventoryThisMonthLoading = selectors.getInventoryThisMonthLoading(state);
    const inventoryThisYearLoading = selectors.getInventoryThisYearLoading(state);
    const inventorySoldStatsLoading = selectors.getInventorySoldStatsLoading(state);
    const inventoryForSourceOrSubsourceLoading = selectors.getInventoryForSourceOrSubsourceLoading(state);

    const soldRevenueBySource = selectors.getRevenueSoldBySource(state);
    const soldCountBySource = selectors.getCountSoldBySource(state);
    const soldRevenueBySubSource = selectors.getRevenueSoldBySubSource(state);
    const soldCountBySubSource = selectors.getCountSoldBySubSource(state);
    const dailySourceStatsLoading = selectors.getDailySourceStatsLoading(state);

    const allSources = selectors.getAllSources(state);
    const allSubsources = selectors.getSubSources(state);
    const allSourcesLoading = selectors.getAllSourcesAndSubsourcesLoading(state);
    return {
        soldRevenueBySource,
        soldCountBySource,
        soldRevenueBySubSource,
        soldCountBySubSource,
        inventoryThisMonth,
        inventoryThisYear,
        inventorySoldStats,
        inventoryForSourceOrSubsource,
        inventoryThisMonthLoading,
        inventoryThisYearLoading,
        inventorySoldStatsLoading,
        inventoryForSourceOrSubsourceLoading,
        selectedSource: selectors.getSelectedSource(state),
        selectedSubsource: selectors.getSelectedSubSource(state),
        allSources,
        allSubsources,
        allSourcesLoading,
        dailySourceStatsLoading
    };
}

export default connect(mapStateToProps, {
    getInventoryThisMonth,
    getInventoryThisYear,
    getInventorySoldStats,
    getInventoryForSourceOrSubsource,
    sourceSelected,
    subsourceSelected,
    sourceForOrdersSelected,
    getAllSourcesAndSubsources,
    getSourceStatsByDateRange
})(Dashboard);

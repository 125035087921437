import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router';
import _ from 'lodash';
import Dashboard from './Modules/Dashboard/Components/Dashboard';
import * as authSelectors from './Modules/Auth/selectors';
import {authorize} from './Modules/Auth/actions';
import Loader from './Modules/core/Components/Loader';

class App extends Component {
    componentWillMount() {
        const {searchParams} = this.props;
        let hashes = searchParams.slice(searchParams.indexOf('?') + 1).split('&');
        let params = {};
        hashes.forEach(hash => {
            let [key, val] = hash.split('=');
            params[key] = decodeURIComponent(val);
        });
        if (!this.props.token || this.props.paramsToken !== params['token']) {
            this.props.authorize(params['token']);
        }
    }

    render() {
        const {authorizing, token} = this.props;
        return (authorizing || !token ?
                <Loader loading={true}/>
                :
                <Route exact path='/' component={Dashboard}/>
        );
    }
}

function mapStateToProps(state) {
    const token = authSelectors.getAuthorizeToken(state);
    const paramsToken = authSelectors.getParamsToken(state);
    const authorizing = authSelectors.isAuthorizing(state);
    const searchParams = _.get(state, 'router.location.search', '');
    return {
        token,
        searchParams,
        authorizing,
        paramsToken
    };
}

export default connect(mapStateToProps, {authorize})(App);

import createActionType from '../core/createActionType';
import { NAME } from './constants';

export const GET_THIS_MONTH = createActionType(`${NAME}/GET_THIS_MONTH`);
export const GET_THIS_YEAR = createActionType(`${NAME}/GET_THIS_YEAR`);
export const GET_SOLD_STATS_BY_DATE_RANGE = createActionType(`${NAME}/GET_SOLD_STATS_BY_DATE_RANGE`);
export const GET_BY_SOURCE_SUBSOURCE = createActionType(`${NAME}/GET_BY_SOURCE_SUBSOURCE`);
export const SOURCE_CHANGED  = createActionType(`${NAME}/SOURCE_CHANGED`);
export const SOURCE_ORDERS_CHANGED  = createActionType(`${NAME}/SOURCE_ORDERS_CHANGED`);
export const SUBSOURCE_CHANGED  = createActionType(`${NAME}/SUBSOURCE_CHANGED`);
export const GET_ALL_SOURCES_SUBSOURCES  = createActionType(`${NAME}/GET_ALL_SOURCES_SUBSOURCES`);
export const MEASUREMENT_CHANGED  = createActionType(`${NAME}/MEASUREMENT_CHANGED`);


export const GET_SOURCE_STATS_BE_DATE_RANGE = createActionType(`${NAME}/GET_SOURCE_STATS_BE_DATE_RANGE`);